import {
    Button,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Select,
    Space,
    Table,
    TablePaginationConfig,
    Tag,
    Typography
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";

import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IPushNotificationService } from "@shared/interfaces/push-notification-service.interface";
import { HttpErrorResponse } from "@shared/models/error/http-error-response";
import { PushNotificationRecurringTypeEnum } from "@shared/types/push-notification/enums/push-notification-recurring-type.enum";
import { PushNotificationTypeEnum } from "@shared/types/push-notification/enums/push-notification-type.enum";
import { PushNotificationDto } from "@shared/types/push-notification/push-notification.dto";
import { ColumnsType } from "antd/es/table";
import AlertModal from "pages/Users/AlertModal/AlertModal";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import ChangeNotificationModal from "./ChangeNotificationModal/ChangeNotificationModal";
import styles from "./Notifications.module.scss";

const ALL = "All";

const defaultNotification: PushNotificationDto = {
    id: "",
    title: "",
    message: "",
    type: PushNotificationTypeEnum.INSTANT,
    dateOfDelivery: null,
    recurringType: PushNotificationRecurringTypeEnum.Daily,
    isSent: false,
};

const ALL_OBJECT = { label: "All", value: ALL };

const TYPE_FILTER = [
    { ...ALL_OBJECT, label: "Filter by type" },
    { label: "One Time", value: PushNotificationTypeEnum.ONE_TIME },
    { label: "Recurring", value: PushNotificationTypeEnum.RECURRING },
    { label: "Now", value: PushNotificationTypeEnum.INSTANT },
];

const getNotificationTypeByType = (type: PushNotificationDto["type"]) => {
    switch (type.toUpperCase()) {
        case "RECURRING": {
            return <Tag color="#4a0000">{type}</Tag>;
        }

        case "ONETIME": {
            return <Tag color="#4b0082">{type}</Tag>;
        }

        case "INSTANT": {
            return <Tag color="#654321">Now</Tag>;
        }

        default:
            return null;
    }
};

const columns = [
    {
        key: "id",
        dataIndex: "id",
        title: "ID",
    },
    {
        key: "title",
        dataIndex: "title",
        title: "Title",
    },
    {
        key: "message",
        dataIndex: "message",
        title: "Body",
    },
    {
        key: "type",
        dataIndex: "type",
        title: "Type",
        render: getNotificationTypeByType,
    },
    {
        key: "dateOfDelivery",
        dataIndex: "dateOfDelivery",
        title: "Date Of Delivery",
        render: (date: Date | null, notification: PushNotificationDto) =>
            notification.type !== PushNotificationTypeEnum.RECURRING && date ?
                dayjs(date).format("YYYY-MM-DD hh:mm:ss A") :
                "N/A",
    },
    {
        key: "dateOfDelivery",
        dataIndex: "dateOfDelivery",
        title: "Next Delivery Date",
        render: (date: Date | null, notification: PushNotificationDto) =>
            notification.type === PushNotificationTypeEnum.RECURRING && date ?
                dayjs(date).format("YYYY-MM-DD hh:mm:ss A") :
                "N/A",
    },
    {
        key: "createdAt",
        dataIndex: "createdAt",
        title: "CreatedAt",
        render: (date: Date | null) =>
            date ?
                dayjs(date).format("YYYY-MM-DD hh:mm:ss A") :
                "N/A",
    },
];

const Notifications = () => {

    const [searchText, setSearchText] = useState("");
    const [type, setType] = useState<PushNotificationDto["type"] | "All">(ALL);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

    const [page, setPage] = useState(1);
    const [currentPageSize, setPageSize] = useState(20);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [selectedNotification, setSelectedNotification] =
        useState<PushNotificationDto | null>(null);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const containerRef = useRef<any>();

    const pushNotificationService = appInject<IPushNotificationService>(
        DI_TOKENS.pushNotificationService,
    );

    const loadNotificationsQuery = useQuery(
        [
            "load-notifications-list",
            page,
            currentPageSize,
            debouncedSearchText,
            type,
        ],
        () =>
            pushNotificationService.getPushNotificationsList({
                page,
                pageSize: currentPageSize,
                query: debouncedSearchText,
                type: type === ALL ? undefined : (type ?? undefined),
            }),
        {
            refetchOnMount: true,
        },
    );

    const createNotificationMutation: UseMutationResult<
        PushNotificationDto,
        unknown,
        PushNotificationDto,
        unknown
    > = useMutation(
        (data: PushNotificationDto): Promise<PushNotificationDto> =>
            pushNotificationService.create(data),
        {
            onSuccess: () => {
                setSelectedNotification(null);
                setIsOpenModal(false);
                loadNotificationsQuery.refetch();
            },
        },
    );

    const updateNotificationMutation: UseMutationResult<
        PushNotificationDto,
        unknown,
        PushNotificationDto,
        unknown
    > = useMutation(
        (data: PushNotificationDto): Promise<PushNotificationDto> =>
            pushNotificationService.update(data),
        {
            onSuccess: () => {
                setSelectedNotification(null);
                setIsOpenModal(false);
                loadNotificationsQuery.refetch();
            },
        },
    );

    const deleteNotificationMutation: UseMutationResult<
        unknown,
        unknown,
        PushNotificationDto,
        unknown
    > = useMutation(
        (data: PushNotificationDto): Promise<any> =>
            pushNotificationService.delete(data),
        {
            onSuccess: () => {
                setSelectedNotification(null);
                setIsAlertModalOpen(false);
                loadNotificationsQuery.refetch();
            },
        },
    );

    const columns_ = useMemo(() => {
        return [
            ...columns,
            {
                key: "action",
                title: "Actions",
                render: (_: null, notification: PushNotificationDto) => {
                    return (
                        <Space
                            size="middle"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <Button
                                type="primary"
                                color="danger"
                                size="small"
                                style={{
                                    outline: "none",
                                }}
                                onClick={() => {
                                    onOpenNotificationCard(notification);
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                style={{
                                    backgroundColor: "#f5222d",
                                    borderColor: "#f5222d",
                                    outline: "none",
                                }}
                                onClick={() => {
                                    setIsAlertModalOpen(true);
                                    setSelectedNotification(notification);
                                }}
                            >
                                Delete
                            </Button>
                        </Space>
                    );
                },
            },
        ] as ColumnsType<PushNotificationDto>;
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if ("offsetHeight" in containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight - 140);
            }
        }, 100);
    }, []);

    // Update debounced search text after a delay
    useEffect(() => {
        const handler = setTimeout(() => {
            setPage(1);
            setDebouncedSearchText(searchText);
        }, 300); // Adjust debounce delay as needed

        // Clear the timeout if the effect re-runs (when searchText changes)
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const onOpenNotificationCard = (notification: PushNotificationDto) => {
        setSelectedNotification(notification);
        setIsOpenModal(true);
    };

    const onTableChange = ({ current, pageSize }: TablePaginationConfig) => {
        setPage(current || 1);
        if (pageSize !== currentPageSize) {
            setPageSize(pageSize || 20);
            setPage(1);
        }
    };

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    };

    const resetFilters = () => {
        setSearchText("");
        setType(ALL);
    };

    const handleSaveDialog = (data: PushNotificationDto) => {
        if (selectedNotification?.id) {
            updateNotificationMutation.mutate({
                ...data,
                id: selectedNotification.id,
            });
        } else {
            if (selectedNotification) {
                createNotificationMutation.mutate(data);
            }
        }
    };

    const handleProceedDialog = (data: PushNotificationDto) => {
        if (selectedNotification?.id) {
            deleteNotificationMutation.mutate({
                ...data,
                id: selectedNotification.id,
            });
        }
    };

    const onCloseDialog = () => {
        setSelectedNotification(null);
        setIsOpenModal(false);
    };

    const handleCloseAlertDialog = () => {
        setIsAlertModalOpen(false);
    };

    const getErrorMessage = (error: any) => {
        if (error) {
            if (error instanceof HttpErrorResponse) {
                return error.errors.get("message" as any) as unknown as string;
            }
            return `Player doesnt have a full set of cards available right now`;
        }
    };

    return (
        <Layout className={styles.tableLayout}>
            {isOpenModal ? (
                <ChangeNotificationModal
                    open={isOpenModal}
                    notification={selectedNotification}
                    onClose={onCloseDialog}
                    onSave={handleSaveDialog}
                    isLoading={
                        createNotificationMutation.isLoading ||
                        updateNotificationMutation.isLoading
                    }
                    isError={
                        createNotificationMutation.isError ||
                        updateNotificationMutation.isError
                    }
                />
            ) : null}

            {isAlertModalOpen ? (
                <AlertModal
                    open={Boolean(isAlertModalOpen)}
                    description={`Are you sure you want to delete the "${selectedNotification?.title}" notification?`}
                    onClose={handleCloseAlertDialog}
                    isLoading={deleteNotificationMutation.isLoading}
                    error={getErrorMessage(deleteNotificationMutation.error)}
                    onProceed={handleProceedDialog}
                />
            ) : (
                <></>
            )}

            <Row
                style={{
                    height: 32,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                <Col
                    span={7}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        rowGap: 5,
                        columnGap: 5,
                    }}
                >
                    <Typography.Title style={{ margin: 0 }} level={5}>
                        Notifications ({loadNotificationsQuery.data?.total})
                    </Typography.Title>

                    <Button
                        type="primary"
                        onClick={() => {
                            setSelectedNotification(defaultNotification);
                            setIsOpenModal(true);
                        }}
                    >
                        Add Notification
                    </Button>
                </Col>
                <Col>
                    <Form.Item className="search-input-container">
                        <Input
                            onChange={handleSearch}
                            value={searchText}
                            name="search"
                            style={{
                                minWidth: 200,
                            }}
                            placeholder="Search by Title or Body"
                        />

                        <Select
                            style={{
                                minWidth: 160,
                            }}
                            options={TYPE_FILTER}
                            value={type}
                            onChange={setType}
                        />

                        <Button type="primary" onClick={resetFilters}>
                            Reset
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Layout.Content
                ref={containerRef}
                className={styles.content}
                style={{ backgroundColor: "white" }}
            >
                <Table
                    rowKey="key"
                    dataSource={loadNotificationsQuery.data?.data || []}
                    onChange={onTableChange}
                    rowClassName={styles.tableRow}
                    columns={columns_}
                    loading={loadNotificationsQuery.isFetching}
                    pagination={{
                        current: page,
                        total: loadNotificationsQuery.data?.total || 0,
                        pageSize: currentPageSize,
                        showSizeChanger: true,
                    }}
                    size="small"
                    scroll={{ y: containerHeight }}
                />
            </Layout.Content>
        </Layout>
    );
};

export default Notifications;

import {
    Col,
    Dropdown,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Table,
    TablePaginationConfig,
    Typography
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IUserService } from "@shared/interfaces/user.interface";
import { HttpErrorResponse } from "@shared/models/error/http-error-response";
import {
    ResetUserAccountDto,
    ResetUserDraftsDto,
    UpdateUserDto,
    UserDto,
} from "@shared/types/user/user.dto";
import { ColumnsType } from "antd/es/table";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import AlertModal from "./AlertModal/AlertModal";
import ChangeUserModal from "./ChangeUserModal/ChangeUserModal";
import styles from "./Users.module.scss";

export type IChangeUserModalType = "draftTokensRemaining" | "dailyDraftCounter" | "ranking";

const items = [
    { key: 'dailyDraftCounter', label: 'Reset Draft' },
    { key: 'redeal-cards', label: 'Re-deal Cards' },
    { key: 'update-ranking', label: 'Update GLO Ranking' },
    { key: 'draftTokensRemaining', label: 'Manage Draft Tokens' },
];

const Users = () => {
    const [searchText, setSearchText] = useState("");
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

    const [page, setPage] = useState(1);
    const [currentPageSize, setPageSize] = useState(20);

    const [containerHeight, setContainerHeight] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [modalType, setModalType] =
        useState<IChangeUserModalType>("dailyDraftCounter");
    const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
    const [isOpenAlertModal, setIsOpenAlertModal] = useState<
        null | "reset-user" | "redeal-cards" | "update-ranking"
    >(null);

    const usersService = appInject<IUserService>(DI_TOKENS.userService);

    const handleMenuItemClick = useCallback((key: string, user: UserDto) => {
        switch (key) {
            case "dailyDraftCounter":
                onOpenUser(user, "dailyDraftCounter");
                break;
            case "redeal-cards":
                setIsOpenAlertModal("redeal-cards");
                setSelectedUser(user);
                break;
            case "update-ranking":
                onOpenUser(user, "ranking");
                break;
            case "draftTokensRemaining":
                onOpenUser(user, "draftTokensRemaining");
                break;
        }
    }, [])

    const columns = useMemo(
        () =>
            [
                {
                    key: "id",
                    dataIndex: "id",
                    title: "ID",
                },
                {
                    key: "nickname",
                    dataIndex: "nickname",
                    title: "Nickname",
                },
                {
                    key: "phoneNumber",
                    dataIndex: "phoneNumber",
                    title: "Phone No.",
                },
                {
                    key: "ranking",
                    dataIndex: "ranking",
                    title: "Ranking",
                    className: "max-w-[90px]",
                    render: (value: number | null) => (value === null ? "N/A" : value),
                },
                {
                    key: "dailyDraftsCounter",
                    dataIndex: "dailyDraftsCounter",
                    title: "Drafts Played",
                    render: (value: number) => value,
                },
                {
                    key: "createdAt",
                    dataIndex: "createdAt",
                    title: "CreatedAt",
                    render: (date: Date | null) =>
                        date ?
                            dayjs(date).format("YYYY-MM-DD hh:mm:ss A") :
                            "N/A",
                },
                {
                    key: "action",
                    title: "Actions",
                    render: (_: null, user: UserDto) => {
                        return (
                            <Space
                                size="middle"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <Dropdown menu={{
                                    items, onClick: (e) => {
                                        handleMenuItemClick(e.key, user);
                                    }
                                }}>
                                    <div style={{
                                        color: "#1677ff",
                                    }}>
                                        Actions <DownOutlined />
                                    </div>
                                </Dropdown>
                            </Space>
                        );
                    },
                },
            ] as ColumnsType<UserDto>,
        [handleMenuItemClick],
    );

    const usersQuery = useQuery(
        ["download-user-list", page, currentPageSize, debouncedSearchText],
        () =>
            usersService.getUsersList({
                page,
                pageSize: currentPageSize,
                query: debouncedSearchText,
            }),
        {
            refetchOnMount: true,
        },
    );

    const updateUserMutation: UseMutationResult<
        UserDto,
        unknown,
        UpdateUserDto,
        unknown
    > = useMutation(
        (data: UpdateUserDto): Promise<UserDto> => usersService.update(data),
        {
            onSuccess: () => {
                setSelectedUser(null);
                setIsOpenModal(false);
                usersQuery.refetch();
            },
        },
    );

    const resetUserDraftsMutation: UseMutationResult<
        unknown,
        unknown,
        ResetUserDraftsDto,
        unknown
    > = useMutation(
        (data: ResetUserDraftsDto): Promise<any> => usersService.reDealCards(data),
        {
            onSuccess: () => {
                setSelectedUser(null);
                setIsOpenAlertModal(null);
                usersQuery.refetch();
            },
        },
    );

    const resetUserAccountMutation: UseMutationResult<
        unknown,
        unknown,
        ResetUserAccountDto,
        unknown
    > = useMutation(
        (data: ResetUserAccountDto): Promise<any> =>
            usersService.resetUserAccount(data),
        {
            onSuccess: () => {
                setSelectedUser(null);
                setIsOpenAlertModal(null);
                usersQuery.refetch();
            },
        },
    );

    const handleCloseDialog = () => {
        setIsOpenModal(false);
        setSelectedUser(null);
    };

    const handleCloseAlertDialog = () => {
        resetUserDraftsMutation.reset();
        setIsOpenAlertModal(null);
    };

    const handleSaveDialog = (data: UserDto) => {
        if (selectedUser?.id) {
            updateUserMutation.mutate({ ...data, id: selectedUser.id });
        }
    };

    const handleProceedDialog = (data: UserDto) => {
        if (selectedUser?.id) {
            if (isOpenAlertModal === "reset-user") {
                resetUserAccountMutation.mutate({ ...data, id: selectedUser.id });
            } else if (isOpenAlertModal === "redeal-cards") {
                resetUserDraftsMutation.mutate({ ...data, id: selectedUser.id });
            }
        }
    };

    const containerRef = useRef<any>();

    const onOpenUser = (
        user: UserDto,
        type: IChangeUserModalType,
    ) => {
        setSelectedUser(user);
        setModalType(type);
        setIsOpenModal(true);
    };

    const onTableChange = ({ current, pageSize }: TablePaginationConfig) => {
        setPage(current || 1);
        if (pageSize !== currentPageSize) {
            setPageSize(pageSize || 20);
            setPage(1);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if ("offsetHeight" in containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight - 140);
            }
        }, 100);
    }, []);

    // Update debounced search text after a delay
    useEffect(() => {
        const handler = setTimeout(() => {
            setPage(1);
            setDebouncedSearchText(searchText);
        }, 300); // Adjust debounce delay as needed

        // Clear the timeout if the effect re-runs (when searchText changes)
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    };

    const getErrorMessage = (error: any) => {
        if (error) {
            if (error instanceof HttpErrorResponse) {
                return error.errors.get("message" as any) as unknown as string;
            }
            return `Player doesnt have a full set of cards available right now`;
        }
    };

    return (
        <Layout className={styles.tableLayout}>
            {isOpenModal ? (
                <ChangeUserModal
                    open={isOpenModal}
                    user={selectedUser}
                    onClose={handleCloseDialog}
                    onSave={handleSaveDialog}
                    isLoading={updateUserMutation.isLoading}
                    isError={updateUserMutation.isError}
                    type={modalType}
                />
            ) : (
                <></>
            )}

            {isOpenAlertModal ? (
                <AlertModal
                    open={Boolean(isOpenAlertModal)}
                    description={
                        isOpenAlertModal === "redeal-cards"
                            ? `Are you sure you want to re-deal the draft cards of ${selectedUser?.nickname} user?`
                            : `Are you sure you want to reset the account of ${selectedUser?.nickname} user?`
                    }
                    onClose={handleCloseAlertDialog}
                    isLoading={updateUserMutation.isLoading}
                    error={getErrorMessage(resetUserDraftsMutation.error)}
                    onProceed={handleProceedDialog}
                />
            ) : (
                <></>
            )}

            <Row
                style={{
                    height: 32,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                <Col
                    span={7}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        rowGap: 5,
                        columnGap: 5,
                        height: "100%",
                    }}
                >
                    <Typography.Title style={{ margin: 0 }} level={5}>
                        Users ({usersQuery.data?.total})
                    </Typography.Title>
                </Col>
                <Col>
                    <Form.Item className="search-input-container">
                        <Input
                            onChange={handleSearch}
                            value={searchText}
                            name="search"
                            style={{
                                minWidth: 200,
                            }}
                            placeholder="Search by Name , Phone number"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Layout.Content
                ref={containerRef}
                className={styles.content}
                style={{ backgroundColor: "white" }}
            >
                <Table
                    rowKey="key"
                    dataSource={usersQuery.data?.data || []}
                    onChange={onTableChange}
                    columns={columns}
                    loading={usersQuery.isFetching}
                    pagination={{
                        current: page,
                        total: usersQuery.data?.total || 0,
                        pageSize: currentPageSize,
                        showSizeChanger: true,
                    }}
                    size="small"
                    scroll={{ y: containerHeight }}
                />
            </Layout.Content>
        </Layout>
    );
};

export default Users;

import { DiEntityIdentifier } from "@core/di/types";

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  baseService: "base-service",
  httpClientService: "http-client-service",
  configService: "config-service",
  storageService: "storage-service",
  authService: "auth-service",
  playerCardService: "player-card-service",
  userService: "user-service",
  settingsService: "settings-service",
  pushNotificationService: "push-notification-service",
  probabilityService: "probability-service",
});

import { DiEntity, DiEntityIdentifier } from "@core/di/types";
import { DI_TOKENS } from "@shared/constants/di";
import { AuthService } from "@shared/services/auth.service";
import { PlayerCardService } from "@shared/services/player-card.service";
import { UserService } from "@shared/services/user.service";
import { PushNotificationService } from "./push-notification.service";
import { ProbabilityService } from "./probability.service";

export const sharedAppServices: Array<{
  diToken: DiEntityIdentifier;
  entity: DiEntity;
}> = [
  { diToken: DI_TOKENS.authService, entity: AuthService },
  { diToken: DI_TOKENS.playerCardService, entity: PlayerCardService },
  {
    diToken: DI_TOKENS.pushNotificationService,
    entity: PushNotificationService,
  },
  { diToken: DI_TOKENS.probabilityService, entity: ProbabilityService },
  { diToken: DI_TOKENS.userService, entity: UserService },
];

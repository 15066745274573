import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { ProbabilityDto } from "@shared/types/probability/probability.dto";
import { ProbabilityListPaginatedDto } from "@shared/types/probability/probability-list-paginated.dto";

@appInjectable()
export class ProbabilityService extends BaseService {
    private configService = appInject<IConfigService>(DI_TOKENS.configService);
    private httpClient = appInject<IHttpClientService>(
        DI_TOKENS.httpClientService,
    );
    private baseURL: string;

    constructor() {
        super();
        this.baseURL = this.configService.baseUrl;
    }

    async createOrUpdate(dtos: ProbabilityDto[]): Promise<ProbabilityDto[]> {
        const { data } = await this.httpClient.put<ProbabilityDto[]>(
            `/probability`,
            { probabilities: dtos },
            {
                baseURL: this.baseURL,
            },
        );

        return data;
    }

    async list(): Promise<ProbabilityListPaginatedDto> {
        const { data } = await this.httpClient.get<ProbabilityListPaginatedDto>(
            "/probability",
            {
                baseURL: this.baseURL,
            },
        );

        return data;
    }
}

import { UserDto } from "@shared/types/user/user.dto";
import { Alert, Button, Form, InputNumber, Modal, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { IChangeUserModalType } from "../Users";

interface IModal {
    open: boolean;
    user: UserDto | null;
    onClose: () => void;
    onSave: (data: UserDto) => void;
    isLoading: boolean;
    isError?: boolean;
    type: IChangeUserModalType;
}

const ChangeUserModal = ({
    open,
    user,
    onClose,
    onSave,
    isLoading,
    isError,
    type,
}: IModal) => {

    const isEditingMode = user?.id;
    const [form] = Form.useForm();
    const [resetting, setResetting] = useState(false);

    useEffect(() => {
        if (user) {
            const formattedUser = {
                ...user,
                ranking: user.ranking === null ? 0 : user.ranking,
            };
            form.setFieldsValue(formattedUser);
        }
    }, [form, user]);

    const handleFinish = (values: UserDto) => {
        const formattedValues = {
            ...values
        };
        onSave(formattedValues);
    };

    const handleReset = () => {
        try {
            const values = form.getFieldsValue();
            setResetting(true);
            handleFinish({ ...values, ranking: null });
        } catch (error) {
            console.error(error);
            setResetting(false);
        }
    };

    return (
        <Modal title={"Change User"} open={open} onCancel={onClose} footer={null}>
            <Form
                key={JSON.stringify(user)}
                form={form}
                requiredMark={false}
                onFinish={handleFinish}
            >
                {isError && (
                    <Form.Item>
                        <Alert
                            message="Error"
                            description="An error occurred while saving the user data. Please try again."
                            type="error"
                            showIcon
                        />
                    </Form.Item>
                )}

                {type === "ranking" ? (
                    <Form.Item
                        name="ranking"
                        label="GLO Ranking"
                        labelCol={{ span: 24, style: { padding: 0 } }}
                        style={{ margin: 0 }}
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                        />
                    </Form.Item>
                ) : null}

                {type === "dailyDraftCounter" ? (
                    <Form.Item
                        name="dailyDraftsCounter"
                        label="Drafts Played"
                        labelCol={{ span: 24, style: { padding: 0 } }}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                type: "number",
                                message: "Daily draft counter must be a number",
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                { label: "0", value: 0 },
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                            ]}
                        />
                    </Form.Item>
                ) : null}

                {type === "draftTokensRemaining" ? (
                    <Form.Item
                        name="draftTokensRemaining"
                        label="Draft Tokens"
                        labelCol={{ span: 24, style: { padding: 0 } }}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                type: "number",
                                message: "Draft tokens must be a number",
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: "100%",
                            }}
                        />
                    </Form.Item>
                ) : null}

                <Space
                    style={{ justifyContent: "flex-end", width: "100%", marginTop: 10 }}
                >
                    <Form.Item>
                        <Button type="default" onClick={onClose}>
                            Cancel
                        </Button>
                    </Form.Item>
                    {type === "ranking" ? (
                        <Form.Item>
                            <Button type="primary" style={{
                                backgroundColor: "#f5222d",
                                borderColor: "#f5222d",
                                outline: "none",
                            }}
                                htmlType="button"
                                onClick={handleReset}
                                loading={resetting}>
                                Reset
                            </Button>
                        </Form.Item>
                    ) : null}
                    <Form.Item>

                        <Button type="primary" htmlType="submit" loading={isLoading && !resetting}>
                            {isEditingMode ? "Save" : "Add"}
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default ChangeUserModal;

import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import { IProbabilityService } from "@shared/interfaces/probability-service.interface";
import { ProbabilityDto } from "@shared/types/probability/probability.dto";
import type { FormProps, GetRef } from "antd";
import {
    Button,
    Col,
    Form,
    InputNumber,
    Layout,
    message,
    Row,
    Space,
    Table,
    Tag,
    Typography
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { HttpErrorResponse } from "@shared/models/error/http-error-response";
import styles from "./Configurations.module.scss";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item extends ProbabilityDto {
    key: React.Key;
}

interface EditableRowProps {
    index: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm<FormProps>();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form as any}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {

    // toast.info("test");
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>();
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex as any}
                rules={[{ required: true, message: "" }]}
            >
                <InputNumber
                    ref={inputRef}
                    min={0}
                    max={100}
                    onPressEnter={save}
                    onBlur={save}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingInlineEnd: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const Configurations = () => {

    const probabilityService = appInject<IProbabilityService>(DI_TOKENS.probabilityService);

    const [dataSource, setDataSource] = useState<Item[]>([]);
    const [messageApi, contextHolder] = message.useMessage();

    const loadProbabilityQuery = useQuery(
        ["probability-list"],
        () => probabilityService.list(),
        {
            onSuccess: (data) => {
                if (data.data.length) {
                    const mappedData = data.data.map((item, index) => ({
                        key: String(index),
                        round: item.round,
                        icon: item.icon,
                        platinum: item.platinum,
                        gold: item.gold,
                        silver: item.silver,
                        bronze: item.bronze
                    }));
                    setDataSource(mappedData);
                }
            }
        }
    );

    const createOrUpdateProbabilityMutation = useMutation(
        (data: ProbabilityDto[]) => probabilityService.createOrUpdate(data),
        {
            onSuccess: () => {
                messageApi.success("Probability list updated successfully");
                loadProbabilityQuery.refetch();
            },
            onError: (error) => {
                messageApi.error(getErrorMessage(error));
            }
        }
    );

    const defaultColumns: (ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
    })[] = [
            {
                dataIndex: "round",
                title: "Round",
                width: "10%",
            },
            {
                title: <Tag color="#8b0000">Icon</Tag>,
                dataIndex: "icon",
                width: "16%",
                editable: true,
            },
            {
                title: <Tag color="#9400d3">Platinum</Tag>,
                dataIndex: "platinum",
                width: "16%",
                editable: true,
            },
            {
                title: <Tag color="#aa6c39">Gold</Tag>,
                dataIndex: "gold",
                width: "16%",
                editable: true,
            },
            {
                title: <Tag color="#c0c0c0">Silver</Tag>,
                dataIndex: "silver",
                width: "16%",
                editable: true,
            },
            {
                title: <Tag color="#5c3a0a">Bronze</Tag>,
                dataIndex: "bronze",
                width: "16%",
                editable: true,
            },
            {
                title: "Total",
                dataIndex: "total",
                width: "10%",
                render: (_, record: any) => {
                    const totalSum =
                        record.icon +
                        record.platinum +
                        record.gold +
                        record.silver +
                        record.bronze;
                    const color = totalSum !== 100 ? "red" : "black";

                    return <div style={{ color }}>{totalSum}</div>;
                },
            },
        ];

    const handleSave = (row: Item) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };

    const handleSaveAll = () => {
        const probabilities = dataSource.map(item => ({
            round: item.round!,
            icon: item.icon,
            platinum: item.platinum,
            gold: item.gold,
            silver: item.silver,
            bronze: item.bronze
        }));

        createOrUpdateProbabilityMutation.mutate(probabilities);
    };

    const getErrorMessage = (error: any) => {
        if (error) {
            if (error instanceof HttpErrorResponse) {
                return error.errors.get("message" as any) as unknown as string;
            }
            return `Unable to save configuration`;
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <Layout className={styles.tableLayout}>
            {contextHolder}
            <Row style={{ height: 32, justifyContent: "space-between" }}>
                <Col span={7}>
                    <Typography.Title style={{ margin: 0 }} level={5}>
                        Configurations
                    </Typography.Title>
                </Col>
                <Col>
                    <Form.Item style={{ margin: 0 }}>
                        <Button
                            type="primary"
                            onClick={handleSaveAll}
                            disabled={createOrUpdateProbabilityMutation.isLoading}
                            loading={createOrUpdateProbabilityMutation.isLoading}
                        >
                            {createOrUpdateProbabilityMutation.isLoading ? "Saving..." : "Save"}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Layout.Content
                className={styles.content}
                style={{ backgroundColor: "white" }}
            >
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <Space direction="vertical" size="small" style={{ width: "100%" }}>
                        <Typography.Text>
                            Odds of receiving a player card in each tier
                        </Typography.Text>
                        <Table
                            components={components}
                            rowClassName={() => "editable-row"}
                            pagination={false}
                            size="small"
                            dataSource={dataSource}
                            columns={columns as ColumnTypes}
                            loading={loadProbabilityQuery.isFetching}
                        />
                    </Space>
                </Space>
            </Layout.Content>
        </Layout>
    );
};

export default Configurations;

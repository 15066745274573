import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IPushNotificationService } from "@shared/interfaces/push-notification-service.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { PushNotificationDto } from "@shared/types/push-notification/push-notification.dto";

@appInjectable()
export class PushNotificationService
  extends BaseService
  implements IPushNotificationService
{
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  async create(dto: PushNotificationDto): Promise<PushNotificationDto> {
    const { data } = await this.httpClient.post<PushNotificationDto>(
      "/push-notification",
      dto,
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async update(dto: PushNotificationDto): Promise<PushNotificationDto> {
    const { data } = await this.httpClient.patch<PushNotificationDto>(
      `/push-notification/${dto.id}`,
      dto,
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async getPushNotificationsList(dto: {
    page?: number;
    pageSize?: number;
    query?: string;
    type?: PushNotificationDto["type"];
  }): Promise<{
    data: PushNotificationDto[];
    total: number;
  }> {
    const { data } = await this.httpClient.get<{
      data: PushNotificationDto[];
      total: number;
    }>("/push-notification/list", {
      params: dto,
      baseURL: this.baseURL,
    });

    return data;
  }

  async delete(dto: PushNotificationDto): Promise<PushNotificationDto> {
    const { data } = await this.httpClient.delete<PushNotificationDto>(
      `/push-notification/${dto.id}`,
      { baseURL: this.baseURL },
    );
    return data;
  }
}

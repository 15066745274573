export const RecurringNotificationTypes = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];

export const NotificationTypes = [
  { label: "Now", value: "Instant" },
  { label: "Schedule", value: "OneTime" },
  { label: "Recurring", value: "Recurring" },
];
